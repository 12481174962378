var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.forNext
    ? _c(
        "a-list-item",
        { staticClass: "cursor-pointer", on: { click: _vm.clickSearchResult } },
        [
          _c("tmwc-content-breadcrumbs", {
            attrs: { items: _vm.breadcrumbJson },
          }),
          _vm._v(" "),
          _c("a-message", { attrs: { "data-title": _vm.title } }, [
            _c("span", {
              attrs: { slot: "title" },
              domProps: { innerHTML: _vm._s(_vm.title) },
              slot: "title",
            }),
            _vm._v(" "),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.snippet) } }),
          ]),
        ],
        1
      )
    : _vm.redesign
    ? _c(
        "a",
        {
          staticClass:
            "block rounded-md border border-gray-500 w-full mb-2 bg-white hover:bg-gray-300",
          attrs: { href: _vm.href, target: _vm.target },
        },
        [
          _c("div", {
            staticClass:
              "w-full text-3xs text-gray-600 py-1 px-2 border-gray-500 border-b",
            domProps: { innerHTML: _vm._s(_vm.breadcrumb) },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-full py-1 px-2" },
            [
              _vm.item.digitalResourceType === "source"
                ? _c("div", {
                    staticClass: "py-1",
                    domProps: {
                      innerHTML: _vm._s(_vm.title + ": " + _vm.snippet),
                    },
                  })
                : [
                    _c("div", {
                      staticClass: "text-sm font-bold",
                      domProps: { innerHTML: _vm._s(_vm.title) },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "py-1 text-sm",
                      domProps: { innerHTML: _vm._s(_vm.snippet) },
                    }),
                  ],
            ],
            2
          ),
        ]
      )
    : _c(
        "a",
        {
          staticClass: "search-modal-result mb-1",
          class: "search-modal-result--" + _vm.item.digitalResourceType,
          attrs: { href: _vm.href, target: _vm.target },
        },
        [
          _c(
            "div",
            { staticClass: "search-modal-result__breadcrumbs" },
            _vm._l(_vm.item.parents, function (parent) {
              return _c(
                "span",
                { staticClass: "search-modal-result__breadcrumb" },
                [_vm._v(_vm._s(parent.title))]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "search-modal-result__content" },
            [
              _vm.item.digitalResourceType === "source"
                ? _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.title + ": " + _vm.snippet),
                    },
                  })
                : [
                    _c("div", {
                      staticClass: "search-modal-result__title",
                      domProps: { innerHTML: _vm._s(_vm.title) },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "search-modal-result__snippet",
                      domProps: { innerHTML: _vm._s(_vm.snippet) },
                    }),
                  ],
            ],
            2
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }