import request, { deleteRequest } from '../../util/request'
import Routing from '../../util/routing'
import DayJsLocale from '../../util/dayjs-locale'

const LOAD_COUNT = 15

class NotificationService {
  constructor (methodSlug, hasNewNotifications) {
    this.methodSlug = methodSlug
    this.notifications = null
    this.hasNewNotifications = hasNewNotifications
    this.hasUnreadNotifications = false
    this.cursor = null
    this.allNotificationsLoaded = false
  }

  hasMethod() {
    return '' !== this.methodSlug
  }

  getHasNewNotifications() {
    return this.hasNewNotifications
  }

  async getNotifications (limit) {
    if (this.notifications === null) {
      await this.loadNextNotifications()
    }
    if (limit) {
      return this.notifications.slice(0, limit)
    }
    return this.notifications
  }

  async markAllNotificationsAsSeen() {
    if (!this.hasNewNotifications || null === this.notifications || 0 === this.notifications.length) {
      return
    }
    if (this.hasMethod()) {
      await request(Routing.generate('mark_all_method_notifications_seen', { notificationId: this.notifications[0].id, methodSlug: this.methodSlug }))
    } else {
      await request(Routing.generate('mark_all_notifications_seen', { notificationId: this.notifications[0].id }))
    }
    this.hasNewNotifications = false
  }

  markAllNotificationsAsRead() {
    const readAt = DayJsLocale.formattedCurrentDateTime()
    this.notifications.forEach((notification) => {
      if (null === notification.readAt) {
        notification.readAt = readAt
      }
    })

    this.updateUnread()

    if (this.hasMethod()) {
      return request(Routing.generate('mark_all_method_notifications_read', { methodSlug: this.methodSlug }))
    }
    return request(Routing.generate('mark_all_notifications_read'))
  }

  get unreadNotifications() {
    return this.notifications.filter(notification => null === notification.readAt)
  }

  updateUnread() {
    this.hasUnreadNotifications = this.unreadNotifications.length > 0;
  }

  updateNew() {
    this.hasNewNotifications = this.notifications.some(notification => null === notification.seenAt)
  }

  async markNotificationAsRead(notification) {
    if (null !== notification.readAt) {
      return
    }
    notification.readAt = DayJsLocale.formattedCurrentDateTime()
    await request(Routing.generate('notification_read', {  notificationId: notification.id }))

    this.updateUnread()
  }

  async markNotificationAsSeen(notification) {
    if (null !== notification.seenAt) {
      return
    }

    notification.seenAt = DayJsLocale.formattedCurrentDateTime()
    await request(Routing.generate('notification_seen', {  notificationId: notification.id }))

    this.updateNew()
  }

  async deleteNotification(notification) {
    const index = this.notifications.indexOf(notification)
    this.notifications.splice(index, 1)
    this.updateNew()
    await deleteRequest(Routing.generate('notification_delete', {  notificationId: notification.id }))
  }

  async loadNextNotifications () {
    if (this.hasMethod()) {
      return await this.loadNotifications(Routing.generate('notifications_for_user_and_method', { methodSlug: this.methodSlug }))
    }
    return await this.loadNotifications(Routing.generate('notifications_for_user'))
  }

  async loadNotifications (url) {
    let params = {
      count: LOAD_COUNT
    }
    if (this.cursor) {
      params.cursor = this.cursor
    }
    const response = await request(url, params)

    const notifications = response.data

    if (notifications.length > 0) {
      this.cursor = notifications[notifications.length - 1].id
    }
    if (this.notifications === null) {
      this.notifications = []
    }
    this.notifications = this.notifications.concat(notifications)

    this.allNotificationsLoaded = notifications.length < LOAD_COUNT

    this.updateUnread();

    return { notifications: this.notifications, added: notifications.length > 0 }
  }
}

export default NotificationService
