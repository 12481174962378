var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.forNext
        ? [
            _vm.loading ? _c("loading-bar") : _vm._e(),
            _vm._v(" "),
            _c(
              "a-list",
              { attrs: { "data-test": "navbar-notification-results-list" } },
              [
                _vm._l(_vm.notifications, function (notification, index) {
                  return _c(
                    "a-list-item",
                    {
                      key: "list-item-" + index,
                      attrs: { "data-test": "navbar-notification-" + index },
                    },
                    [
                      _c(
                        "a-message",
                        {
                          attrs: {
                            state:
                              null === notification.seenAt ? "unread" : "check",
                            clickable: "",
                            deletable: "",
                          },
                          on: {
                            clicked: function ($event) {
                              return _vm.markNotificationAsSeenAndReadAndGotoSubject(
                                notification
                              )
                            },
                            clickedState: function ($event) {
                              return _vm.clickState(notification)
                            },
                            clickedDelete: function ($event) {
                              return _vm.deleteNotification(notification)
                            },
                          },
                        },
                        [
                          _c("span", {
                            attrs: { slot: "footer" },
                            domProps: {
                              innerHTML: _vm._s(_vm.getTimeAgo(notification)),
                            },
                            slot: "footer",
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              attrs: {
                                slot:
                                  null === notification.readAt ? "title" : "",
                              },
                              slot: null === notification.readAt ? "title" : "",
                            },
                            [_vm._v(_vm._s(notification.message))]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c("a-list-item", [
                  _vm.notifications.length === 0 && !_vm.loading
                    ? _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("component.notifications.no_notifications")
                          ),
                        },
                      })
                    : _vm._e(),
                ]),
              ],
              2
            ),
            _vm._v(" "),
            _c("span", { ref: "loadMore" }),
          ]
        : _vm.useRedesign
        ? [
            _c(
              "div",
              { staticClass: "overflow-auto px-4", style: _vm.maxHeightStyle },
              [
                _vm.loading
                  ? _c("loading-bar")
                  : [
                      _vm._l(_vm.notifications, function (notification, index) {
                        return _c("notification", {
                          key: notification.id,
                          attrs: {
                            index: index,
                            notification: notification,
                            "show-method": _vm.showMethod,
                            "is-preview": true,
                            "use-redesign": true,
                          },
                          on: {
                            markRead: _vm.markNotificationAsSeen,
                            delete: _vm.deleteNotification,
                            click: _vm.markNotificationAsReadAndGotoSubject,
                          },
                        })
                      }),
                      _vm._v(" "),
                      _vm.notifications.length === 0 && !_vm.loading
                        ? _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t(
                                  "component.notifications.no_notifications"
                                )
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "px-4" }, [
              _vm.loading
                ? _c("div", { staticClass: "py-2" }, [
                    _vm._v(_vm._s(_vm.$t("component.notifications.loading"))),
                  ])
                : _c("a", {
                    staticClass: "block py-2 cursor-pointer",
                    attrs: { tabindex: "0" },
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("component.notifications.view_all")
                      ),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.clickShowAll.apply(null, arguments)
                      },
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                        return _vm.clickShowAll.apply(null, arguments)
                      },
                    },
                  }),
            ]),
          ]
        : [
            _c("div", { staticClass: "notifications-preview" }, [
              _c("span", {
                staticClass: "notifications__title",
                domProps: {
                  innerHTML: _vm._s(_vm.$t("component.notifications.title")),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "notifications-preview__notifications",
                  style: _vm.maxHeightStyle,
                },
                [
                  _vm.loading
                    ? _c("div", { staticClass: "loader loader--centered py-2" })
                    : [
                        _vm._l(
                          _vm.notifications,
                          function (notification, index) {
                            return _c("notification", {
                              key: notification.id,
                              attrs: {
                                index: index,
                                notification: notification,
                                "show-method": _vm.showMethod,
                                "is-preview": true,
                              },
                              on: {
                                markRead: _vm.markNotificationAsSeen,
                                delete: _vm.deleteNotification,
                                click: _vm.markNotificationAsReadAndGotoSubject,
                              },
                            })
                          }
                        ),
                        _vm._v(" "),
                        _vm.notifications.length === 0 && !_vm.loading
                          ? _c("div", {
                              staticClass: "notifications__empty",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "component.notifications.no_notifications"
                                  )
                                ),
                              },
                            })
                          : _vm._e(),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _c("a", {
                staticClass: "notifications__show-all",
                attrs: { "data-test": "navbar-notifications-show-all" },
                domProps: {
                  innerHTML: _vm._s(_vm.$t("component.notifications.view_all")),
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.clickShowAll.apply(null, arguments)
                  },
                },
              }),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }