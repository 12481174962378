<template>
  <div v-if="redesign">
    <div class="flexbox">
      <search-field
        ref="searchField"
        :placeholder="translation('placeholder')"
        :for-next="forNext"
        @input="onInput"
        @search="search"
      ></search-field>
    </div>
    <div class="mt-2" v-if="methodName && !onlyMethod">
      <a-checkbox v-if="forNext" :checked="inMethod" @change="changeInMethod" v-html="$t('component.search.search_in_method', { methodName })" ></a-checkbox>
      <label v-else class="search-form__method flexbox flex-align-items--center text-sm">
        <check-element :size="5" ref="checkbox" class="mr-2" v-model="inMethod"></check-element>
        <span v-html="translation('in_method', { methodName })"></span>
      </label>
    </div>
  </div>
  <div v-else>
    <div class="search-form__form flexbox">
      <input
        type="search"
        class="form-group__field search-form__input"
        :placeholder="placeHolder"
        @search="search"
        v-model="realSearchString"
        autocomplete="off"
        ref="searchField"
      />
      <a
        :class="['button', 'button--icon-only', 'button--primary', 'button--large', {'button--disabled': state.isLoading || state.loadMoreIsLoading}]"
        @click.prevent="search"
      ><i class="icon-search"></i></a>
    </div>
    <div class="p-1" v-if="methodName && !onlyMethod">
      <label class="search-form__method flexbox flex-align-items--center">
        <input type="checkbox" v-model="inMethod" class="mr-1"/>
        <span v-html="$t('component.search.search_in_method', { methodName })"></span>
      </label>
    </div>
  </div>
</template>

<script>
import { SearchState } from './SearchState'
import SearchField from '../../redesign/search/SearchField'
import CheckElement from '../../redesign/CheckElement'

export default {
  components: {
    SearchField,
    CheckElement
  },
  props: {
    searchString: {
      type: String,
      default: ''
    },
    onlyMethod: {
      type: Boolean,
      default: false
    },
    forNext: {
      type: Boolean,
      default: false
    },
    methodName: String,
    state: SearchState,
    redesign: Boolean
  },
  data () {
    return {
      inMethod: this.onlyMethod,
      realSearchString: this.searchString
    }
  },
  mounted () {
    if (!this.redesign) {
      this.$refs.searchField.focus()
    }
  },
  methods: {
    changeInMethod(event) {
      this.inMethod = event.currentTarget.checked
    },
    translation(id, props) {
      return this.$t(`component.search.${this.forNext ? 'next.' : ''}search_${id}`, props)
    },
    search () {
      this.$emit('search', this.realSearchString, this.inMethod || this.onlyMethod)
    },
    onInput (value) {
      this.realSearchString = value
    }
  }
}
</script>
