var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.redesign
    ? _c("div", [
        _c(
          "div",
          { staticClass: "flexbox" },
          [
            _c("search-field", {
              ref: "searchField",
              attrs: {
                placeholder: _vm.translation("placeholder"),
                "for-next": _vm.forNext,
              },
              on: { input: _vm.onInput, search: _vm.search },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.methodName && !_vm.onlyMethod
          ? _c(
              "div",
              { staticClass: "mt-2" },
              [
                _vm.forNext
                  ? _c("a-checkbox", {
                      attrs: { checked: _vm.inMethod },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("component.search.search_in_method", {
                            methodName: _vm.methodName,
                          })
                        ),
                      },
                      on: { change: _vm.changeInMethod },
                    })
                  : _c(
                      "label",
                      {
                        staticClass:
                          "search-form__method flexbox flex-align-items--center text-sm",
                      },
                      [
                        _c("check-element", {
                          ref: "checkbox",
                          staticClass: "mr-2",
                          attrs: { size: 5 },
                          model: {
                            value: _vm.inMethod,
                            callback: function ($$v) {
                              _vm.inMethod = $$v
                            },
                            expression: "inMethod",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.translation("in_method", {
                                methodName: _vm.methodName,
                              })
                            ),
                          },
                        }),
                      ],
                      1
                    ),
              ],
              1
            )
          : _vm._e(),
      ])
    : _c("div", [
        _c("div", { staticClass: "search-form__form flexbox" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.realSearchString,
                expression: "realSearchString",
              },
            ],
            ref: "searchField",
            staticClass: "form-group__field search-form__input",
            attrs: {
              type: "search",
              placeholder: _vm.placeHolder,
              autocomplete: "off",
            },
            domProps: { value: _vm.realSearchString },
            on: {
              search: _vm.search,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.realSearchString = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "a",
            {
              class: [
                "button",
                "button--icon-only",
                "button--primary",
                "button--large",
                {
                  "button--disabled":
                    _vm.state.isLoading || _vm.state.loadMoreIsLoading,
                },
              ],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.search.apply(null, arguments)
                },
              },
            },
            [_c("i", { staticClass: "icon-search" })]
          ),
        ]),
        _vm._v(" "),
        _vm.methodName && !_vm.onlyMethod
          ? _c("div", { staticClass: "p-1" }, [
              _c(
                "label",
                {
                  staticClass:
                    "search-form__method flexbox flex-align-items--center",
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.inMethod,
                        expression: "inMethod",
                      },
                    ],
                    staticClass: "mr-1",
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.inMethod)
                        ? _vm._i(_vm.inMethod, null) > -1
                        : _vm.inMethod,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.inMethod,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.inMethod = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.inMethod = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.inMethod = $$c
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("component.search.search_in_method", {
                          methodName: _vm.methodName,
                        })
                      ),
                    },
                  }),
                ]
              ),
            ])
          : _vm._e(),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }