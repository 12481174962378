<template>
  <a-list v-if="forNext">
    <search-result-item
      v-for="item in results"
      :key="item.contentIdentifier"
      :item="item"
      :redesign="redesign"
      :for-next="forNext"
    ></search-result-item>
  </a-list>
  <div v-else :class="{'pb-2 pt-2': redesign}">
    <search-result-item
      v-for="item in results"
      :key="item.contentIdentifier"
      :item="item"
      :redesign="redesign"
      :for-next="forNext"
    ></search-result-item>
  </div>
</template>

<script>
import SearchResultItem from './SearchResultItem'
export default {
  components: { SearchResultItem },
  props: {
    results: Array,
    redesign: Boolean,
    forNext: {
      type: Boolean,
      default: false
    }
  }
}
</script>
