var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "search-modal",
      class: { "p-3 w-96 sm:w-128": _vm.redesign && !_vm.forNext },
      attrs: { "data-test": "search-modal" },
    },
    [
      _vm.forNext
        ? _c(
            "div",
            { staticClass: "p-3 sticky top-0 bg-white" },
            [
              _c("search-form", {
                attrs: {
                  redesign: _vm.redesign,
                  "search-string": _vm.searchString,
                  "method-name": _vm.methodName,
                  "only-method": _vm.onlyMethod,
                  state: _vm.state,
                  "for-next": _vm.forNext,
                },
                on: { search: _vm.search },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-5 flex gap-1" },
                _vm._l(
                  _vm.searchResults.contentTypeFacets,
                  function (contentType) {
                    return _c(
                      "a-button",
                      {
                        key: contentType.value,
                        attrs: {
                          size: "small",
                          color: "blue",
                          variant: _vm.isActiveContentType(contentType)
                            ? "default"
                            : "ghost",
                          disabled: _vm.state.isLoading,
                          "data-test": "search-facet-" + contentType.value,
                        },
                        on: {
                          clicked: function ($event) {
                            return _vm.selectContentType(contentType.value)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t(
                                "component.search.facet." + contentType.value
                              )
                            ) +
                            " (" +
                            _vm._s(contentType.count) +
                            ")\n      "
                        ),
                      ]
                    )
                  }
                ),
                1
              ),
            ],
            1
          )
        : [
            _c("search-form", {
              attrs: {
                redesign: _vm.redesign,
                "search-string": _vm.searchString,
                "method-name": _vm.methodName,
                "only-method": _vm.onlyMethod,
                state: _vm.state,
                "for-next": _vm.forNext,
              },
              on: { search: _vm.search },
            }),
            _vm._v(" "),
            _vm.state.showContentTypeFacets
              ? _c("content-types", {
                  attrs: {
                    redesign: _vm.redesign,
                    "content-types": _vm.searchResults.contentTypeFacets,
                    "active-content-type": _vm.activeContentType,
                  },
                  on: { select: _vm.selectContentType },
                })
              : _vm._e(),
          ],
      _vm._v(" "),
      _vm.redesign
        ? [
            _vm.state.isLoading
              ? [
                  _vm.forNext
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "relative pt-10 w-full flex justify-center",
                        },
                        [
                          _c("a-loader", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("component.search.searching")
                              ),
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "relative py-20" },
                        [
                          _c("loading-bar", { attrs: { backdrop: false } }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-full text-center text-blue fill-current animate-pulse",
                            },
                            [
                              _c("inline-icon", {
                                attrs: { size: 10, icon: "magnifier" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "w-full mt-3 text-center",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("component.search.searching")
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.state.showNoResults
              ? _c(
                  "div",
                  {
                    staticClass: "relative",
                    class: { "py-20": !_vm.forNext, "pt-10": _vm.forNext },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "w-full text-center fill-current",
                        class: {
                          "text-blue": !_vm.forNext,
                          "text-blue-next-600": _vm.forNext,
                        },
                      },
                      [
                        _c("inline-icon", {
                          attrs: { size: 10, icon: "magnifier" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "w-full mt-3 text-center" }, [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("component.search.empty_result")
                          ),
                        },
                      }),
                      _c("br"),
                      _vm._v('\n        "'),
                      _c(
                        "span",
                        {
                          staticClass: "font-bold",
                          class: {
                            "text-blue": !_vm.forNext,
                            "text-blue-next-600": _vm.forNext,
                          },
                        },
                        [_vm._v(_vm._s(_vm.searchString))]
                      ),
                      _vm._v('"'),
                      _c("br"),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("component.search.empty_result_advice")
                          ),
                        },
                      }),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "resultsContainer",
                class: {
                  "max-h-half-screen pr-2 -mr-2 overflow-auto scrollbar scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-300 scrollbar-thumb-rounded scrollbar-track-rounded":
                    !_vm.forNext,
                },
                attrs: { "data-test": "results-container" },
              },
              [
                _vm.state.showResults
                  ? _c("search-result", {
                      attrs: {
                        redesign: _vm.redesign,
                        results: _vm.searchResults.items,
                        "for-next": _vm.forNext,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { ref: "loadMoreContainer" }, [
                  _vm.state.showLoadMore
                    ? _c(
                        "div",
                        {
                          ref: "loadMoreResults",
                          staticClass: "py-1 w-full text-center",
                        },
                        [_c("inline-icon", { attrs: { icon: "magnifier" } })],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.state.loadMoreIsLoading
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "py-1 w-full text-center text-blue fill-current animate-pulse",
                        },
                        [_c("inline-icon", { attrs: { icon: "magnifier" } })],
                        1
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ]
        : [
            _vm.state.isLoading
              ? _c("div", { staticClass: "search-modal-loader py-10" }, [
                  _vm._m(0),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("component.search.searching")) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.state.showNoResults
              ? _c(
                  "div",
                  { staticClass: "search-modal-no-results py-10 px-8" },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("component.search.empty_result")
                        ),
                      },
                    }),
                    _vm._v('\n      "'),
                    _c("span", { staticClass: "search-highlight" }, [
                      _vm._v(_vm._s(_vm.searchString)),
                    ]),
                    _vm._v('"'),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("component.search.empty_result_advice")
                        ),
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "resultsContainer",
                staticClass: "search-modal-results",
                class: { "pl-1 pr-1 pb-1": _vm.state.showContentTypeFacets },
              },
              [
                _vm.state.showResults
                  ? _c("search-result", {
                      attrs: {
                        results: _vm.searchResults.items,
                        "for-next": _vm.forNext,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { ref: "loadMoreContainer" }, [
                  _vm.state.showLoadMore
                    ? _c(
                        "div",
                        {
                          ref: "loadMoreResults",
                          staticClass:
                            "search-modal-loader search-modal-loader--load-more py-2",
                        },
                        [_vm._m(2)]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.state.loadMoreIsLoading
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "search-modal-loader search-modal-loader--load-more py-2",
                        },
                        [_vm._m(3)]
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "search-modal-loader__icon mb-2" }, [
      _c("span", { staticClass: "icon-search" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "search-modal-no-results__icon mb-2" }, [
      _c("span", { staticClass: "icon-search" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "search-modal-loader__icon" }, [
      _c("span", { staticClass: "icon-search" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "search-modal-loader__icon" }, [
      _c("span", { staticClass: "icon-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }