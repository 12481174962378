<template>
  <div>
    <template v-if="forNext">
      <loading-bar v-if="loading"></loading-bar>
      <a-list data-test="navbar-notification-results-list">
        <a-list-item :data-test="'navbar-notification-' + index "v-for="(notification, index) in notifications" :key="'list-item-' + index">
          <a-message
            :state="null === notification.seenAt ? 'unread' : 'check'"
            @clicked="markNotificationAsSeenAndReadAndGotoSubject(notification)"
            @clickedState="clickState(notification)"
            @clickedDelete="deleteNotification(notification)"
            clickable
            deletable
          >
            <span slot="footer" v-html="getTimeAgo(notification)"></span>
            <span :slot="null === notification.readAt ? 'title' : ''">{{ notification.message }}</span>
          </a-message>
        </a-list-item>
        <a-list-item>
          <div v-if="notifications.length === 0 && !loading" v-html="$t('component.notifications.no_notifications')"></div>
        </a-list-item>
      </a-list>
      <span ref="loadMore"></span>
    </template>
    <template v-else-if="useRedesign">
      <div class="overflow-auto px-4" :style="maxHeightStyle">
        <loading-bar v-if="loading"></loading-bar>
        <template v-else>
          <notification
            v-for="(notification, index) in notifications"
            :key="notification.id"
            :index="index"
            :notification="notification"
            :show-method="showMethod"
            :is-preview="true"
            :use-redesign="true"
            v-on:markRead="markNotificationAsSeen"
            v-on:delete="deleteNotification"
            v-on:click="markNotificationAsReadAndGotoSubject"
          ></notification>

          <div v-if="notifications.length === 0 && !loading" v-html="$t('component.notifications.no_notifications')"></div>
        </template>
      </div>
      <div class="px-4">
        <div v-if="loading" class="py-2">{{$t('component.notifications.loading')}}</div>
        <a v-else @click.prevent="clickShowAll" @keydown.enter.prevent="clickShowAll" tabindex="0" class="block py-2 cursor-pointer" v-html="$t('component.notifications.view_all')"></a>
      </div>
    </template>
    <template v-else>
      <div class="notifications-preview">
        <span class="notifications__title" v-html="$t('component.notifications.title')"></span>
        <div class="notifications-preview__notifications" :style="maxHeightStyle">
          <div v-if="loading" class="loader loader--centered py-2"></div>
          <template v-else>
            <notification
              v-for="(notification, index) in notifications"
              :key="notification.id"
              :index="index"
              :notification="notification"
              :show-method="showMethod"
              :is-preview="true"
              v-on:markRead="markNotificationAsSeen"
              v-on:delete="deleteNotification"
              v-on:click="markNotificationAsReadAndGotoSubject"
            ></notification>
            <div v-if="notifications.length === 0 && !loading" class="notifications__empty" v-html="$t('component.notifications.no_notifications')"></div>
          </template>
        </div>
        <a data-test="navbar-notifications-show-all" class="notifications__show-all" @click.prevent="clickShowAll" v-html="$t('component.notifications.view_all')"></a>
      </div>
    </template>
  </div>
</template>

<script>
  import LoadingBar from '../../redesign/LoadingBar'
import DayJsLocale from '../../util/dayjs-locale'
import Routing from '../../util/routing'
import Notification from './Notification'
import NotificationService from './NotificationService'

  export default {
    components: {
      LoadingBar,
      Notification
    },
    props: {
      notificationService: NotificationService,
      useRedesign: {
        type: Boolean,
        default: false
      },
      forNext: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        showMethod: !this.notificationService.hasMethod(),
        maxHeightStyle: this.forNext ? {} : {'max-height': '1000px'},
        notifications: [],
        loading: false
      }
    },
    mounted() {
      this.loadNextNotifications();

      if (!this.forNext) {
        this.updateMaxHeight();
        window.addEventListener('resize', this.updateMaxHeight)
      } else {
        this.createInfiniteScroll()
      }
    },
    destroyed() {
      if (!this.forNext) {
        window.removeEventListener('resize', this.updateMaxHeight)
      } else {
        this.$options.observer.disconnect()
        this.$options.observer = null
      }
    },
    methods: {
      loadNextNotifications() {
        if (this.notificationService.allNotificationsLoaded) {
          return;
        }

        this.loading = true

        this.notificationService.loadNextNotifications(this.forNext ? null : 5).then(response => {
          this.notifications = this.forNext ? response.notifications : response.notifications.slice(0, 5)
          this.loading = false

          this.$emit('notifications-loaded')
        })
      },
      getTimeAgo(notification) {
        return DayJsLocale.timeAgo(notification.createdAt)
      },
      updateMaxHeight() {
        this.maxHeightStyle = {'max-height': (window.innerHeight - 150) + 'px'}
      },
      clickState(notification) {
        if (null === notification.readAt) {
          return this.markNotificationAsSeen(notification)
        }
        this.navigateToTarget(notification)
      },
      markNotificationAsSeen(notification) {
        this.notificationService.markNotificationAsSeen(notification)
      },
      deleteNotification(notification) {
        this.notificationService.deleteNotification(notification)
      },
      async markNotificationAsSeenAndReadAndGotoSubject(notification) {
        await this.notificationService.markNotificationAsRead(notification)
        await this.notificationService.markNotificationAsSeen(notification)
        this.navigateToTarget(notification)
      },
      navigateToTarget(notification) {
        window.location.href = Routing.generate(notification.route.name, notification.route.params)
      },
      clickShowAll() {
        this.$emit('show-notifications-modal')
      },
      onIntersect() {
        this.loadNextNotifications()
      },
      createInfiniteScroll() {
        this.$options.observer = new IntersectionObserver(entries => {
          entries.forEach(entry => entry.isIntersecting && !this.loading && this.onIntersect())
        })
        this.$options.observer.observe(this.$refs.loadMore);
      }
    }
  }
</script>
