<template>
  <div v-if="redesign" class="flex w-100">
    <content-type-button
      v-for="contentType in contentTypes"
      :key="contentType.value"
      :redesign="redesign"
      :facet-value="contentType"
      :is-active="isActiveValue(contentType.value)"
      @select="selectContentType(contentType.value)"
    ></content-type-button>
  </div>
  <div v-else class="search-modal-facets">
    <content-type-button
      v-for="contentType in contentTypes"
      :key="contentType.value"
      :redesign="redesign"
      :facet-value="contentType"
      :is-active="isActiveValue(contentType.value)"
      @click.native="selectContentType(contentType.value)"
    ></content-type-button>
  </div>
</template>

<script>
import ContentTypeButton from './ContentTypeButton'

export default {
  components: { ContentTypeButton },
  props: {
    contentTypes: Array,
    activeContentType: String,
    redesign: Boolean
  },
  methods: {
    isActiveValue (value) {
      return this.activeContentType === value
    },
    selectContentType (value) {
      this.$emit('select', value)
    }
  }
}
</script>
