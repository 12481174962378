import { routePost } from '../../util/request'

class SearchService {
  async search (searchString, searchScope, contentType, scrollId) {
    const query = { searchString, contentType, scrollId }

    if ('' !== searchScope.scopeName) {
      query[searchScope.scopeName] = searchScope.scopeValue
    }

    const { data } = await routePost('edition_search', {}, query)
    return data
  }
}

export default SearchService
