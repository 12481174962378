export class SearchState {
  get isLoading() {
    return false
  }
  get showNoResults() {
    return false
  }
  get showResults() {
    return false
  }
  get showContentTypeFacets() {
    return false
  }
  get showLoadMore() {
    return false
  }
  get loadMoreIsLoading() {
    return false
  }
}

export class InitialState extends SearchState {}

export class LoadingState extends SearchState {
  get isLoading() {
    return true
  }
}

export class LoadingStateWithFacets extends SearchState {
  get isLoading() {
    return true
  }
  get showContentTypeFacets() {
    return true
  }
}

export class ResultState extends SearchState {
  constructor (searchResults) {
    super()
    this._searchResults = searchResults
  }

  get showNoResults() {
    return this._searchResults.items.length === 0
  }
  get showResults() {
    return this._searchResults.items.length > 0
  }
  get showContentTypeFacets() {
    return true
  }
  get showLoadMore() {
    return this._searchResults.scrollId !== null
  }
}

export class ResultLoadMoreState extends ResultState {
  get showLoadMore() {
    return false
  }
  get loadMoreIsLoading() {
    return true
  }
}
