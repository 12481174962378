var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.classes,
      attrs: { "data-test": "navbar-notification-" + _vm.index },
    },
    [
      _vm.useRedesign
        ? [
            _c(
              "div",
              {
                staticClass: "flex border-b border-gray-500 py-4 items-center",
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "flex-1 cursor-pointer",
                    class: { "font-bold": !_vm.isRead },
                    attrs: { tabindex: "0" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.clickNotification.apply(null, arguments)
                      },
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                        return _vm.clickNotification.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm.showMethod
                      ? _c("div", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.notification.methodName)),
                          ]),
                          _vm._v(
                            " - " +
                              _vm._s(_vm.notification.message) +
                              "\n        "
                          ),
                        ])
                      : _c("div", [_vm._v(_vm._s(_vm.notification.message))]),
                    _vm._v(" "),
                    _c("div", {
                      staticClass:
                        "text-3xs sm:text-2xs lg:text-xs text-gray-575",
                      domProps: { innerHTML: _vm._s(_vm.time) },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "a",
                    {
                      class: {
                        "text-blue": _vm.isRead,
                        "text-gray-400 cursor-pointer": !_vm.isRead,
                      },
                      attrs: { tabindex: _vm.isRead ? -1 : 0 },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.markNotificationAsRead.apply(
                            null,
                            arguments
                          )
                        },
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                          return _vm.markNotificationAsRead.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_c("inline-icon", { attrs: { icon: "checkmark" } })],
                    1
                  ),
                ]),
                _vm._v(" "),
                !_vm.isPreview
                  ? _c("div", { staticClass: "ml-4" }, [
                      _c(
                        "a",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { tabindex: "0" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.deleteNotification.apply(
                                null,
                                arguments
                              )
                            },
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              $event.preventDefault()
                              return _vm.deleteNotification.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_c("inline-icon", { attrs: { icon: "trashcan" } })],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        : [
            _c(
              "a",
              {
                staticClass: "notifications__link",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.clickNotification.apply(null, arguments)
                  },
                },
              },
              [
                _vm.showMethod
                  ? _c(
                      "span",
                      { staticClass: "notifications__notification-message" },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "notifications__notification-message-method",
                          },
                          [_vm._v(_vm._s(_vm.notification.methodName))]
                        ),
                        _vm._v(
                          " - " +
                            _vm._s(_vm.notification.message) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.showMethod
                  ? _c(
                      "span",
                      { staticClass: "notifications__notification-message" },
                      [_vm._v(_vm._s(_vm.notification.message))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("span", {
                  staticClass: "notifications__notification-time",
                  domProps: { innerHTML: _vm._s(_vm.time) },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "notifications__seen" }, [
              _c("a", {
                staticClass: "icon-check",
                class: { "icon-check--read": _vm.isRead },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.markNotificationAsRead.apply(null, arguments)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            !_vm.isPreview
              ? _c("div", { staticClass: "notifications__delete" }, [
                  _c("a", {
                    staticClass: "icon-trash",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.deleteNotification.apply(null, arguments)
                      },
                    },
                  }),
                ])
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }