<template>
  <a-list-item v-if="forNext" class="cursor-pointer" @click="clickSearchResult">
    <tmwc-content-breadcrumbs :items="breadcrumbJson"></tmwc-content-breadcrumbs>
    <a-message :data-title="title">
      <span slot="title" v-html="title"></span>
      <span v-html="snippet"></span>
    </a-message>
  </a-list-item>
  <a v-else-if="redesign" class="block rounded-md border border-gray-500 w-full mb-2 bg-white hover:bg-gray-300" :href="href" :target="target">
    <div class="w-full text-3xs text-gray-600 py-1 px-2 border-gray-500 border-b" v-html="breadcrumb"></div>
    <div class="w-full py-1 px-2">
      <div v-if="item.digitalResourceType === 'source'" class="py-1" v-html="title + ': ' + snippet"></div>
      <template v-else>
        <div v-html="title" class="text-sm font-bold"></div>
        <div v-html="snippet" class="py-1 text-sm"></div>
      </template>
    </div>
  </a>
  <a v-else class="search-modal-result mb-1" :class="'search-modal-result--' + item.digitalResourceType" :href="href" :target="target">
    <div class="search-modal-result__breadcrumbs">
      <span v-for="parent in item.parents" class="search-modal-result__breadcrumb">{{ parent.title }}</span>
    </div>
    <div class="search-modal-result__content">
      <div v-if="item.digitalResourceType === 'source'" v-html="title + ': ' + snippet"></div>
      <template v-else>
        <div class="search-modal-result__title" v-html="title"></div>
        <div class="search-modal-result__snippet" v-html="snippet"></div>
      </template>
    </div>
  </a>
</template>

<script>
import Routing from '../../util/routing';

export default {
  props: {
    item: Object,
    redesign: Boolean,
    forNext: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    highlightSpan () {
      if (this.forNext) {
        return '<span class="text-blue-next-600 font-bold">';
      }
      if (this.redesign) {
        return '<span class="text-blue font-bold">';
      }
      return '<span class="search-highlight">';
    },
    title () {
      return this.item.title.replace('<em>', this.highlightSpan).replace('</em>', '</span>')
    },
    snippet () {
      return `"${this.item.snippet.replace('<em>', this.highlightSpan).replace('</em>', '</span>')}..."`
    },
    breadcrumb () {
      return this.item.parents.map(parent => parent.title).join(' > ')
    },
    breadcrumbJson () {
      return JSON.stringify(this.item.parents.map(item => {
        return {title: item.title};
      }))
    },
    target () {
      if (this.item.hasOwnProperty('staticHref')) {
        return '_blank'
      }
      return '_self'
    },
    href () {
      if (this.item.hasOwnProperty('staticHref')) {
        return this.item.staticHref
      }
      let contentIdentifier = this.item.contentIdentifier
      if (!contentIdentifier.startsWith('thip.')) {
        contentIdentifier = this.item.parents[this.item.parents.length - 1].contentIdentifier
      }
      return Routing.generate('thip_id_link', {
        streamCode: this.item.arrangementCode,
        contentReference: contentIdentifier.replace('thip.', '')
      })
    }
  },
  methods: {
    clickSearchResult(event) {
      if ('_blank' === this.target) {
        window.open(this.href, this.target)
      } else {
        window.location.href = this.href
      }
      return false;
    }
  }
}
</script>
