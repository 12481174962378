class SearchScope {
  constructor (scopeName, scopeValue) {
    this.scopeName = scopeName
    this.scopeValue = scopeValue
  }

  static forStream (streamCode) {
    return new SearchScope('streamCode', streamCode)
  }

  static forMethod (methodName) {
    return new SearchScope('methodName', methodName)
  }

  static global () {
    return new SearchScope('', '')
  }
}

export default SearchScope
