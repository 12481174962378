<template>
  <div class="relative">
    <input
      class="rounded-lg border border-gray-500 focus:outline-none bg-white px-4 py-2.5 w-full focus:ring-2"
      :class="{
        'hover:border-blue active:border-blue focus:border-blue focus:ring-blue': !forNext,
        'hover:border-blue-next-600 active:border-blue-next-600 focus:border-blue-next-600 focus:ring-blue-next-600': forNext
      }"
      type="search"
      :placeholder="placeholder"
      @search="$emit('search')"
      @keyup.enter="$emit('search')"
      :value="value"
      @input="$emit('input', $event.target.value)"
      autocomplete="off"
      data-test="search-field-input"
    />
    <div class="absolute top-0 right-0 h-full w-12 flex justify-center items-center">
      <div
        data-test="search-field-submit-button"
        class="cursor-pointer pb-1"
        :class="{'hover:text-blue': !forNext, 'hover:text-blue-next-600': forNext }"
        @click.prevent="$emit('search')"
      >
        <inline-icon icon="magnifier" :size="5"></inline-icon>
      </div>
    </div>


  </div>
</template>

<style scoped>
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
</style>

<script>

import InlineIcon from '../InlineIcon'

export default {
  components: {
    InlineIcon
  },
  data() {
    return {
      searchString: this.value,
    }
  },
  props: {
    value: String,
    placeholder: String,
    forNext: {
      type: Boolean,
      default: false
    }
  }
}
</script>
