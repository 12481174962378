<template>
  <a
    v-if="redesign"
    href="#"
    @click.prevent="$emit('select')"
    :data-test="'search-facet-' + facetValue.value"
    class="cursor-pointer pt-1 pb-1 flex-auto text-2xl"
    :class="{
      'border-b border-gray-500': !isActive,
      'font-bold border-black border-b-2': isActive,
    }"
  >
    {{ $t('component.search.facet.' + facetValue.value) }}
    <span class="font-normal text-sm">({{ facetValue.count }})</span>
  </a>
  <div
    v-else
    class="search-modal-facet"
    :class="{ 'search-modal-facet--active': isActive }"
  >
    <i :class="iconClass"></i>
    {{ $t('component.search.facet.' + facetValue.value) }}
    <span class="search-modal-facet__count">[{{ facetValue.count }}]</span>
  </div>
</template>

<script>
const icons = {
  glossentry: 'icon-idea',
  theory: 'icon-bookmark',
  source: 'icon-open-book',
};

export default {
  props: {
    facetValue: Object,
    isActive: Boolean,
    redesign: Boolean,
  },
  computed: {
    iconClass() {
      return icons[this.facetValue.value] || '';
    },
  },
};
</script>
