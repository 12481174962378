var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.forNext
    ? _c(
        "a-list",
        _vm._l(_vm.results, function (item) {
          return _c("search-result-item", {
            key: item.contentIdentifier,
            attrs: {
              item: item,
              redesign: _vm.redesign,
              "for-next": _vm.forNext,
            },
          })
        }),
        1
      )
    : _c(
        "div",
        { class: { "pb-2 pt-2": _vm.redesign } },
        _vm._l(_vm.results, function (item) {
          return _c("search-result-item", {
            key: item.contentIdentifier,
            attrs: {
              item: item,
              redesign: _vm.redesign,
              "for-next": _vm.forNext,
            },
          })
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }