var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.redesign
    ? _c(
        "div",
        { staticClass: "flex w-100" },
        _vm._l(_vm.contentTypes, function (contentType) {
          return _c("content-type-button", {
            key: contentType.value,
            attrs: {
              redesign: _vm.redesign,
              "facet-value": contentType,
              "is-active": _vm.isActiveValue(contentType.value),
            },
            on: {
              select: function ($event) {
                return _vm.selectContentType(contentType.value)
              },
            },
          })
        }),
        1
      )
    : _c(
        "div",
        { staticClass: "search-modal-facets" },
        _vm._l(_vm.contentTypes, function (contentType) {
          return _c("content-type-button", {
            key: contentType.value,
            attrs: {
              redesign: _vm.redesign,
              "facet-value": contentType,
              "is-active": _vm.isActiveValue(contentType.value),
            },
            nativeOn: {
              click: function ($event) {
                return _vm.selectContentType(contentType.value)
              },
            },
          })
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }