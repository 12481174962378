import dayjs from 'dayjs'
import 'dayjs/locale/nl'

import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)

const CURRENT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export default class DayJsLocale {
  static timeAgo(time) {
    return dayjs(time).locale('nl').fromNow()
  }
  static formattedCurrentDateTime() {
    return dayjs().locale('nl').format(CURRENT_DATETIME_FORMAT)
  }
}
