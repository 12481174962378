var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative" }, [
    _c("input", {
      staticClass:
        "rounded-lg border border-gray-500 focus:outline-none bg-white px-4 py-2.5 w-full focus:ring-2",
      class: {
        "hover:border-blue active:border-blue focus:border-blue focus:ring-blue":
          !_vm.forNext,
        "hover:border-blue-next-600 active:border-blue-next-600 focus:border-blue-next-600 focus:ring-blue-next-600":
          _vm.forNext,
      },
      attrs: {
        type: "search",
        placeholder: _vm.placeholder,
        autocomplete: "off",
        "data-test": "search-field-input",
      },
      domProps: { value: _vm.value },
      on: {
        search: function ($event) {
          return _vm.$emit("search")
        },
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.$emit("search")
        },
        input: function ($event) {
          return _vm.$emit("input", $event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "absolute top-0 right-0 h-full w-12 flex justify-center items-center",
      },
      [
        _c(
          "div",
          {
            staticClass: "cursor-pointer pb-1",
            class: {
              "hover:text-blue": !_vm.forNext,
              "hover:text-blue-next-600": _vm.forNext,
            },
            attrs: { "data-test": "search-field-submit-button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.$emit("search")
              },
            },
          },
          [_c("inline-icon", { attrs: { icon: "magnifier", size: 5 } })],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }