var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.redesign
    ? _c(
        "a",
        {
          staticClass: "cursor-pointer pt-1 pb-1 flex-auto text-2xl",
          class: {
            "border-b border-gray-500": !_vm.isActive,
            "font-bold border-black border-b-2": _vm.isActive,
          },
          attrs: {
            href: "#",
            "data-test": "search-facet-" + _vm.facetValue.value,
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.$emit("select")
            },
          },
        },
        [
          _vm._v(
            "\n  " +
              _vm._s(_vm.$t("component.search.facet." + _vm.facetValue.value)) +
              "\n  "
          ),
          _c("span", { staticClass: "font-normal text-sm" }, [
            _vm._v("(" + _vm._s(_vm.facetValue.count) + ")"),
          ]),
        ]
      )
    : _c(
        "div",
        {
          staticClass: "search-modal-facet",
          class: { "search-modal-facet--active": _vm.isActive },
        },
        [
          _c("i", { class: _vm.iconClass }),
          _vm._v(
            "\n  " +
              _vm._s(_vm.$t("component.search.facet." + _vm.facetValue.value)) +
              "\n  "
          ),
          _c("span", { staticClass: "search-modal-facet__count" }, [
            _vm._v("[" + _vm._s(_vm.facetValue.count) + "]"),
          ]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }