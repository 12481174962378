<template>
  <div :class="classes" :data-test="'navbar-notification-' + index">
    <template v-if="useRedesign">
      <div class="flex border-b border-gray-500 py-4 items-center">
        <a @click.prevent="clickNotification" @keydown.enter.prevent="clickNotification" tabindex="0" class="flex-1 cursor-pointer" :class="{'font-bold': !isRead}">
          <div v-if="showMethod">
            <span>{{ notification.methodName }}</span> - {{ notification.message }}
          </div>
          <div v-else>{{ notification.message }}</div>
          <div class="text-3xs sm:text-2xs lg:text-xs text-gray-575" v-html="time"></div>
        </a>
        <div>
          <a :class="{ 'text-blue': isRead, 'text-gray-400 cursor-pointer': !isRead }" :tabindex="isRead ? -1 : 0" @click.prevent="markNotificationAsRead" @keydown.enter.prevent="markNotificationAsRead">
            <inline-icon icon="checkmark"></inline-icon>
          </a>
        </div>
        <div v-if="!isPreview" class="ml-4">
          <a @click.prevent="deleteNotification" @keydown.enter.prevent="deleteNotification" tabindex="0" class="cursor-pointer">
            <inline-icon icon="trashcan"></inline-icon>
          </a>
        </div>
      </div>

    </template>
    <template v-else>
      <a @click.prevent="clickNotification" class="notifications__link">
          <span v-if="showMethod" class="notifications__notification-message">
            <span class="notifications__notification-message-method">{{ notification.methodName }}</span> - {{ notification.message }}
          </span>
        <span v-if="!showMethod" class="notifications__notification-message">{{ notification.message }}</span>
        <span class="notifications__notification-time" v-html="time"></span>
      </a>
      <div class="notifications__seen">
        <a class="icon-check" :class="{ 'icon-check--read': isRead }" @click.prevent="markNotificationAsRead"></a>
      </div>
      <div v-if="!isPreview" class="notifications__delete">
        <a class="icon-trash" @click.prevent="deleteNotification"></a>
      </div>
    </template>
  </div>
</template>

<script>
  import DayJsLocale from '../../util/dayjs-locale'
  import InlineIcon from '../../redesign/InlineIcon'

  export default {
    components: {
      InlineIcon
    },
    props: {
      notification: Object,
      showMethod: Boolean,
      isPreview: Boolean,
      useRedesign: {
        type: Boolean,
        default: false
      },
      index: Number
    },
    computed: {
      classes() {
        if (this.useRedesign) {
          return {}
        }
        return {
          'notifications__notification notifications-modal__notification': true
        }
      },
      isRead() {
        return null !== this.notification.readAt
      },
      time() {
        return DayJsLocale.timeAgo(this.notification.createdAt)
      }
    },
    methods: {
      markNotificationAsRead() {
        this.$emit('markRead', this.notification)
      },
      deleteNotification() {
        this.$emit('delete', this.notification)
      },
      clickNotification() {
        this.$emit('click', this.notification)
      }
    }
  }
</script>
