<template>
  <div
    data-test="search-modal"
    class="search-modal"
    :class="{ 'p-3 w-96 sm:w-128': redesign && !forNext }"
  >
    <div v-if="forNext" class="p-3 sticky top-0 bg-white">
      <search-form
        @search="search"
        :redesign="redesign"
        :search-string="searchString"
        :method-name="methodName"
        :only-method="onlyMethod"
        :state="state"
        :for-next="forNext"
      />
      <div class="mt-5 flex gap-1">
        <a-button
          size="small"
          color="blue"
          v-for="contentType in searchResults.contentTypeFacets"
          :key="contentType.value"
          :variant="isActiveContentType(contentType) ? 'default' : 'ghost'"
          @clicked="selectContentType(contentType.value)"
          :disabled="state.isLoading"
          :data-test="'search-facet-' + contentType.value"
        >
          {{ $t('component.search.facet.' + contentType.value) }} ({{ contentType.count }})
        </a-button>
      </div>
    </div>
    <template v-else>
      <search-form
        @search="search"
        :redesign="redesign"
        :search-string="searchString"
        :method-name="methodName"
        :only-method="onlyMethod"
        :state="state"
        :for-next="forNext"
      />

      <content-types
        v-if="state.showContentTypeFacets"
        :redesign="redesign"
        :content-types="searchResults.contentTypeFacets"
        :active-content-type="activeContentType"
        @select="selectContentType"
      />
    </template>

    <template v-if="redesign">
      <template v-if="state.isLoading">
        <div v-if="forNext" class="relative pt-10 w-full flex justify-center">
          <a-loader v-html="$t('component.search.searching')"></a-loader>
        </div>
        <div v-else class="relative py-20">
          <loading-bar :backdrop="false"></loading-bar>
          <div class="w-full text-center text-blue fill-current animate-pulse">
            <inline-icon :size="10" icon="magnifier"></inline-icon>
          </div>
          <div class="w-full mt-3 text-center" v-html="$t('component.search.searching')"></div>
        </div>
      </template>

      <div v-if="state.showNoResults" class="relative" :class="{'py-20': !forNext, 'pt-10': forNext}">
        <div class="w-full text-center fill-current" :class="{'text-blue': !forNext, 'text-blue-next-600': forNext}">
          <inline-icon :size="10" icon="magnifier"></inline-icon>
        </div>
        <div class="w-full mt-3 text-center">
          <span v-html="$t('component.search.empty_result')"></span><br />
          "<span class="font-bold" :class="{'text-blue': !forNext, 'text-blue-next-600': forNext}">{{
            searchString
          }}</span>"<br /><span v-html="$t('component.search.empty_result_advice')"></span>
        </div>
      </div>

      <div
        data-test="results-container"
        ref="resultsContainer"
        :class="{'max-h-half-screen pr-2 -mr-2 overflow-auto scrollbar scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-300 scrollbar-thumb-rounded scrollbar-track-rounded': !forNext}"
      >
        <search-result
          v-if="state.showResults"
          :redesign="redesign"
          :results="searchResults.items"
          :for-next="forNext"
        />
        <div ref="loadMoreContainer">
          <div
            v-if="state.showLoadMore"
            ref="loadMoreResults"
            class="py-1 w-full text-center"
          >
            <inline-icon icon="magnifier"></inline-icon>
          </div>
          <div
            v-if="state.loadMoreIsLoading"
            class="py-1 w-full text-center text-blue fill-current animate-pulse"
          >
            <inline-icon icon="magnifier"></inline-icon>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-if="state.isLoading" class="search-modal-loader py-10">
        <div class="search-modal-loader__icon mb-2">
          <span class="icon-search"></span>
        </div>
        {{ $t('component.search.searching') }}
      </div>

      <div
        v-if="state.showNoResults"
        class="search-modal-no-results py-10 px-8"
      >
        <div class="search-modal-no-results__icon mb-2">
          <span class="icon-search"></span>
        </div>
        <span v-html="$t('component.search.empty_result')"></span>
        "<span class="search-highlight">{{ searchString }}</span>"<br />
        <span v-html="$t('component.search.empty_result_advice')"></span>
      </div>
      <div
        class="search-modal-results"
        ref="resultsContainer"
        :class="{ 'pl-1 pr-1 pb-1': state.showContentTypeFacets }"
      >
        <search-result
          v-if="state.showResults"
          :results="searchResults.items"
          :for-next="forNext"
        />
        <div ref="loadMoreContainer">
          <div
            v-if="state.showLoadMore"
            ref="loadMoreResults"
            class="search-modal-loader search-modal-loader--load-more py-2"
          >
            <div class="search-modal-loader__icon">
              <span class="icon-search"></span>
            </div>
          </div>
          <div
            v-if="state.loadMoreIsLoading"
            class="search-modal-loader search-modal-loader--load-more py-2"
          >
            <div class="search-modal-loader__icon">
              <span class="icon-search"></span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import InlineIcon from '../../redesign/InlineIcon';
import LoadingBar from '../../redesign/LoadingBar';
import ContentTypes from './ContentTypes';
import SearchForm from './SearchForm';
import SearchResult from './SearchResult';
import SearchScope from './SearchScope';
import SearchService from './SearchService';
import {
InitialState,
LoadingState,
LoadingStateWithFacets,
ResultLoadMoreState,
ResultState,
} from './SearchState';

const searchService = new SearchService();

class SearchResults {
  constructor() {
    this.scrollId = null;
    this.items = [];
    this.contentTypeFacets = [];
  }

  reset() {
    this.scrollId = null;
    this.items = [];
  }

  setResults(items, scrollId, contentTypeFacets) {
    this.items = this.items.concat(items);

    // Make sure theory is always the first option
    this.contentTypeFacets = contentTypeFacets.reverse().sort((a, b) => {
      if (a.value === 'theory') {
        return -1;
      }
      if (b.value === 'theory') {
        return 1;
      }
      return a.value.localeCompare(b.value);
    });
    this.scrollId = scrollId;
  }
}

export default {
  components: {
    ContentTypes,
    SearchForm,
    SearchResult,
    InlineIcon,
    LoadingBar,
  },
  props: {
    methodName: String,
    streamCode: String,
    redesign: Boolean,
    forNext: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const onlyMethod = '' === this.streamCode && '' !== this.methodName;

    return {
      state: new InitialState(),
      searchString: '',
      inMethod: onlyMethod,
      onlyMethod: onlyMethod,
      activeContentType: 'theory',
      searchScope: SearchScope.forStream(this.streamCode),
      searchResults: new SearchResults(),
    };
  },
  mounted() {
    this.$options.loadMoreObserver = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && this.$refs.loadMoreResults) {
          this.loadMore();
        }
      },
      {
        root: this.$refs.resultsContainer,
        rootMargin: this.redesign ? '0px 0px 0px 0px' : '0px 0px -50px 0px',
        threshold: [0, 0.25, 0.5, 0.75, 1],
      }
    );
    this.$options.loadMoreObserver.observe(this.$refs.loadMoreContainer);
  },
  destroyed() {
    this.$options.loadMoreObserver.disconnect();
  },
  methods: {
    async search(searchString, inMethod) {
      if (this.state.isLoading || this.state.loadMoreIsLoading) {
        return;
      }
      this.state = new LoadingState();
      this.searchString = searchString;
      this.inMethod = inMethod;
      this.searchScope = this.getSearchScope();
      this.searchResults = new SearchResults();
      await this.performSearch();
    },
    getSearchScope() {
      if (this.inMethod && '' !== this.methodName) {
        return SearchScope.forMethod(this.methodName);
      }
      if (!this.inMethod && '' !== this.streamCode) {
        return SearchScope.forStream(this.streamCode);
      }
      return SearchScope.global();
    },
    selectContentType(contentType) {
      if (contentType === this.activeContentType) {
        return;
      }

      this.activeContentType = contentType;

      this.state = new LoadingStateWithFacets();
      this.searchResults.reset();
      this.performSearch();
    },
    isActiveContentType(contentType) {
      return this.activeContentType === contentType.value;
    },
    loadMore() {
      this.state = new ResultLoadMoreState(this.searchResults);
      this.performSearch();
    },
    async performSearch() {
      try {
        const { results, facets } = await searchService.search(
          this.searchString,
          this.searchScope,
          this.activeContentType,
          this.searchResults.scrollId
        );
        const scrollId = results.count < 20 ? null : results.scrollId;
        this.searchResults.setResults(
          results.items,
          scrollId,
          facets.find((facet) => 'digitalResourceType' === facet.field).facet
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.state = new ResultState(this.searchResults);
      }
    },
  },
};
</script>
